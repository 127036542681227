<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo />

          <h2 class="brand-text text-primary ml-1">
            RESIDENT
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          ĐĂNG NHẬP HỆ THỐNG QUẢN TRỊ
        </b-card-title>
        <b-card-text class="mb-2">
          Trang này chỉ dành cho quản trị viên
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="phone"
              label="Số điện thoại"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  name="login-phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="0868987355"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mật khẩu</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              class="mt-2"
            >
              Đăng nhập
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue';
import Logo from '@/layouts/components/Logo.vue';
import { required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import md5 from 'md5';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';
import { getHomeRouteForLoggedInUser, updateUserAbilities } from '@/auth/utils';

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      phone: '',
      password: '',
      // validation rules
      required,
      loggingIn: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    login() {
      this.loggingIn = true;
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              phone: this.phone,
              password: md5(this.password),
              role: 'admin',
            })
            .then(response => {
              this.loggingIn = false;
              const { user, accessToken, refreshToken } = response.data;
              useJwt.setToken(accessToken);
              useJwt.setRefreshToken(refreshToken);
              localStorage.setItem('userData', JSON.stringify(user));

              useJwt.getPermissions().then(permissionResponse => {
                updateUserAbilities(permissionResponse.data);
                this.$ability.update(permissionResponse.data);
                this.$router
                  .replace(getHomeRouteForLoggedInUser())
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Xin chào ${user.name}`,
                        icon: 'UserIcon',
                        variant: 'success',
                        text: `Bạn đang đăng nhập với vai trò ${user.role ? user.role.name : 'chủ nhà'}!`,
                      },
                    });
                  });
                this.loggingIn = false;
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: error.response.data.message ? error.response.data.message : 'Tài khoản chưa được phân quyền',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
                this.loggingIn = false;
                localStorage.removeItem('userData');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('accessToken');
              });
            })
            .catch(error => {
              //
              if (error.response && error.response.data && error.response.data.errors) {
                this.$refs.loginForm.setErrors(error.response.data.errors);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: error.response.data.message ? error.response.data.message : 'Bạn ơi đợi chút nhé, Resident đang cập nhật hệ thống trong vòng 1 phút . Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              }
              this.loggingIn = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
